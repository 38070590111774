/* global EM */
import React, { Component } from "react";
import { Button } from "reactstrap";
import Spinner from "../Spinner";
import Routes from '../../app/Routes'
import ConfirmMaziCode from './ConfirmMaziCode'
import LocationCalendar from "./LocationCalendar";
export default class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineRegistration:EM.event.get().onlineBooking,
      isOpen:false,
      popeUpAction:null,
      location:EM.location.get(),
      showCalendar:this.props.location?.state?.openCalendar?true:false
    };
    this.openPopUp = this.openPopUp.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.handleValidCode = this.handleValidCode.bind(this);
    this.confirmAndDownload = this.confirmAndDownload.bind(this);
    this.updateOnlineRegistration = this.updateOnlineRegistration.bind(this);
  }
  // async componentDidMount(){
  //   this.setState({location:EM.location.get()})
  // }
  updateOnlineRegistration(){
     
      const self=this
      const onlineRegistration = this.state.onlineRegistration
      EM.event.executePut({eventId:EM.event.get().eventId, onlineBooking:!onlineRegistration},null,Routes.clientApi.updateEventBooking, {},true)
      .then((result)=>{
        self.setState({onlineRegistration:!self.state.onlineRegistration})
      });
  }
  handleValidCode(){
    this.setState({isOpen:false})
    if(this.state.popeUpAction==='suspend'){
      this.updateOnlineRegistration()
    }else if(this.state.popeUpAction==="appointment") {
      this.setState({showCalendar:true})
    }else{
      this.confirmAndDownload()
    }

  }
  handleDownload = (locationId) => {
    var url = 'https://flashopllc.com/api/appointment/printschedule?LocationId='
 
    window.open(`${url+locationId}`,)
  }
  confirmAndDownload(){
    this.handleDownload(this.state.location.locationId)
    this.setState({isPopOpen:false})
  }
  openPopUp(popeUpAction){
    if(popeUpAction==="appointment") {
      if(this.state.showCalendar) {
        this.setState({showCalendar:false})
        if(this.props.location.state) {
          this.props.history.replace({ ...this.props.history.location, state: undefined });
        }
        return
      }
    }
    this.setState({isOpen:true,popeUpAction})
  }
  closePopUp(){
    this.setState({isOpen:false})
  }
  render() {
    if (EM.allLoaded(EM.event, EM.location)) {
      let event = EM.event.get();
      let location=EM.location.get()
      let company=EM.company.get()
      return (
        <div className="step step1">
          {!this.state.onlineRegistration && <p>Online booking for this event has been temporarily disabled.</p>}
          <div className="border border-primary border-2 text-dark text-uppercase p-3 d-flex w-100">
            {event.deal}
          </div>
          <p className="my-4">{event.description}</p>
          {!this.state.onlineRegistration && <p style={{color:'red'}}>The online signup is temporarily suspended.</p>}
          <div>
          <Button disabled={!this.state.onlineRegistration} className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" onClick={()=>{this.props.next({},this.props.step)}}>Next</Button>
          </div>
          <div className="row mt-3">
               <div className="col-sm text-center">_________For Official Use Only_________</div>
              </div>
         {(company && location && company.companyConfig.mazibook===true && (location.maziOrganizationCode!==null && location.maziOrganizationCode!=='')) && <>
         <Button className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" onClick={() => { this.openPopUp('print') }} style={{color:'black !important',fontWeight:'600'}}>Download PDF of Schedule</Button>
         <Button className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" onClick={() => { this.openPopUp('appointment') }} style={{color:'black !important',fontWeight:'600'}}>{!this.state.showCalendar?"Manage Appointments":"Close Appointments"}</Button>
         <Button className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2" onClick={()=>this.openPopUp('suspend')}>{this.state.onlineRegistration===true?'Suspend Online Registration':'Resume Online Registration'}</Button>
          <ConfirmMaziCode isValid={(data)=>{if(data==true)this.handleValidCode()}} isOpen={this.state.isOpen} onClose={this.state.closePopUp} close={this.closePopUp}/>
          {this.state.showCalendar?(
            <LocationCalendar company={company} location={this.state.location}/>
          ):null}
          </>}
        </div>
      );
    }
    return (
      <div className={this.props.className}>
        <Spinner text={EM.t("util.loading")} classes="small" />
      </div>
    );
  }
}
