/* global EM */
/* eslint no-throw-literal: 0 */
import Routes from './Routes';
import * as fetchingActions from '../entities/actions/fetching';

export default class API{ 
    constructor(authenticator, instance, loginMode){

        this.baseApiAddress = 'https://flashopllc.com/api/';
        //this.baseApiAddress = 'http://203.215.175.50:5000/api/';
        //this.baseApiAddress = 'http://localhost:5000/api/';
        this.sendGridbaseApiAddress = "https://api.sendgrid.com/v3/"
        this.Authenticator = authenticator;
        this.abortController = null;
        this.instance = instance;
        this.loginMode = loginMode;

        this.resetSignal();
    }

    abortPendingFetches(){
        this.abortController.abort();
        this.resetSignal();
    }

    resetSignal(){
        if (AbortController){
            this.abortController = new AbortController();
        }else{
            this.abortController = { signal: null, abort: function(){} };
        }
    }

    async login(email, password){
        const url = Routes.compose(Routes.clientApi.login, {  });
        return await this.loginFetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify({Username:email,Password:password,IsAssociate:true}) }, {});        
    } 
    async register(body){
        const url = Routes.compose(Routes.clientApi.company, {  });
        return await this.loginFetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify(body) }, {});        
    } 
    async registerCompany(body){
        const url = Routes.compose(Routes.clientApi.companySignup, {  });
        return await this.loginFetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify(body) }, {});        
    } 
    async createUser(body){
        const url = Routes.compose(Routes.clientApi.user, {  });
        return await this.loginFetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify(body) }, {});        
    } 
    
    async forgetPasword(email){
        const url = Routes.compose(Routes.clientApi.forgetPassword, {  });
        return await this.loginFetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify({Username:email,IsAssociate:true}) }, {});        
    } 

    async loadTranslation(Lang) {
        const url = Routes.compose(Routes.clientApi.translation, { Lang: Lang }, { build: process.env.REACT_APP_BUILD });
        return this.fetchStaticJson(url, {});
    } 

    async loadHelp(CID, Name, Lang, IsMetaData) {
        const url = Routes.compose(Routes.clientApi.help, { CID: CID, Name: Name, Lang: Lang, IsMeta: IsMetaData });
        return await this.fetch(this.baseApiAddress + url, {}, Name);
    }        
    
    async updateHelp(Name, Lang, Content) {
        const url = Routes.compose(Routes.clientApi.help, { Name: Name, Lang: Lang });
        return await this.fetch(this.baseApiAddress + url, { method: 'PUT', body: Content }, Name);
    }       

    async loadAccount(loginMode) {
        let qs = (loginMode ? 'loginMode=' + loginMode : null); 
        const url = Routes.compose(Routes.clientApi.account, null, qs);
        return this.fetch(this.baseApiAddress + url, {}, 'Account');
    }    
    async loadEntityByParent(Entity, isSilent,parentDetail,getUrl){
        let params = {...(parentDetail ? parentDetail:{}), Entity: Entity}
        let url = Routes.compose(Routes.clientApi.childEntity, params);
        if(parentDetail && getUrl)
        {   
            url = Routes.compose(getUrl,params);
        }        
        return this.fetch(this.baseApiAddress + url, {}, isSilent ? null : Entity);        
    }

    async loadSingleEntity(Entity, isSilent,routeParams,getUrl){      
        let params = {...(routeParams ? routeParams:{}), Entity: Entity}
        let url = Routes.compose(Routes.clientApi.byId, params);
        if(routeParams && getUrl)
        {   
            url = Routes.compose(getUrl,params);
        }        
        return this.fetch(this.baseApiAddress + url, {}, isSilent ? null : Entity);        
    }

    async searchEntity(Entity, isSilent,routeParams,searchUrl,qs){ 
        let params = {...(routeParams ? routeParams:{}), Entity: Entity}
        let url = Routes.compose(Routes.clientApi.search, params,qs);
        if(routeParams && searchUrl)
            url = Routes.compose(searchUrl,params,qs);
        return this.fetch(this.baseApiAddress + url, {}, isSilent ? null : Entity);        
    }

    async loadDomainEntity(Entity, isSilent){
        const url = Routes.compose(Routes.clientApi.domainEntity, { Entity: Entity });
        return this.fetch(this.baseApiAddress + url, {}, isSilent ? null : Entity);        
    }

    async auditDomainEntity(DomainId, Entity, EntityId){
        const url = Routes.compose(Routes.clientApi.auditEntity, { DomainId: DomainId, Entity: Entity, EntityId: EntityId });
        return this.fetch(this.baseApiAddress + url, {}, Entity);        
    }

    async createDomainEntity(Entity, Item,createUrl,parentRecordDetail){
        let url = Routes.compose(Routes.clientApi.domainEntity, { Entity: Entity });
        if(parentRecordDetail && createUrl)
        {
            let params = {...(parentRecordDetail ? parentRecordDetail:{}), Entity: Entity}
            url = Routes.compose(createUrl,params);
        }        
        return this.fetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify(Item) }, Entity);        
    }  
    
    async execute(Entity,data,requestUrl,qs,parentRecordDetail, method){       
        let params =  {Entity: Entity };
        method = method || 'GET';
        if(parentRecordDetail)
            params = {...(parentRecordDetail ? parentRecordDetail:{}), Entity: Entity}
            
        let url = Routes.compose(requestUrl, params,(qs||null));
        let options = {method:method}

        if(data)
            options.body = JSON.stringify(data);

        return this.fetch(this.baseApiAddress + url,options,Entity)
    }

    async updateDomainEntity(Entity, Item,editUrl,parentRecordDetail){
        let url = Routes.compose(Routes.clientApi.domainEntity, { Entity: Entity });
        if(parentRecordDetail && editUrl)
        {
            let params = {...(parentRecordDetail ? parentRecordDetail:{}), Entity: Entity}
            url = Routes.compose(editUrl,params);
        }        
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(Item) }, Entity);        
    }  
    
    async deleteDomainEntity(Entity, ids,deleteUrl,parentRecordDetail){   
        let url = Routes.compose(Routes.clientApi.domainEntity, { Entity: Entity });
        if(parentRecordDetail && deleteUrl)
        {
            let params = {...(parentRecordDetail ? parentRecordDetail:{}), Entity: Entity}
            url = Routes.compose(deleteUrl,params);
        }     
        return this.fetch(this.baseApiAddress + url, { method: 'DELETE', body: JSON.stringify(ids) }, Entity);        
    }      

    async importDomainEntity(DomainId, Entity, Items, isSilent, modsOnly){
        const qs = modsOnly ? { MODSONLY: true } : null;
        const url = Routes.compose(Routes.clientApi.domainEntityImport, { DomainId: DomainId, Entity: Entity }, qs);
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(Items) }, isSilent ? null : Entity);        
    }  
    
    async clearDomainEntity(DomainId, Entity){
        const url = Routes.compose(Routes.clientApi.domainEntityClear, { DomainId: DomainId, Entity: Entity });
        return this.fetch(this.baseApiAddress + url, { method: 'DELETE' }, Entity);   
    }

    async loadDomainEntityFile(DomainId, Entity, Id){
        const url = Routes.compose(Routes.clientApi.domainEntityFile, { DomainId: DomainId, Entity: Entity, Id: Id });
        return this.fetchFile(this.baseApiAddress + url, {}, Entity + ' File');        
    }

    async updateDomainEntityFile(DomainId, Entity, Id, Data){
        const url = Routes.compose(Routes.clientApi.domainEntityFile, { DomainId: DomainId, Entity: Entity, Id: Id });
        return this.fetchFile(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(Data) }, null);        
    }    

 	async uploadDomainFile(Entity, Item, uploadUrl){
        let url = Routes.compose(uploadUrl, { Entity: Entity });
        return this.fetchFile(this.baseApiAddress + url, { method: 'POST', body: Item }, null);        
    } 
    async regenerateProjections(DomainId, ScheduleId, isSilent){
        const url = Routes.compose(Routes.clientApi.projections, { DomainId, ScheduleId }, 'output=1');
        return this.fetch(this.baseApiAddress + url, {}, 'Projections', isSilent);        
    }    

    async loadAdminEntity(tenantId, entity, entityId){   
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: entity, EntityId: entityId});
        return this.fetch(this.baseApiAddress + url, {}, entity);        
    }       

    async createAdminEntity(tenantId, entity, newItem, entityId){
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: entity, EntityId:entityId ? entityId:""});
        return this.fetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify(newItem) }, entity);           
    }

    async deleteAdminEntity(tenantId, entity, ids){
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: entity, EntityId:"" });
        return this.fetch(this.baseApiAddress + url, { method: 'DELETE', body: JSON.stringify(ids) }, entity);        
    }

    async updateAdminEntity(tenantId, entity, item){
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: entity, EntityId:"" });
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(item) }, entity);        
    }    

    async importAdminEntity(tenantId, entity, items, isSilent){
        const url = Routes.compose(Routes.adminApi.adminEntityImport, { TenantId: tenantId, Entity: entity, EntityId:"" });
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(items) }, isSilent ? null : entity); 
    }        
    
    async switchTenant(newTenantId){
        const url = Routes.compose(Routes.adminApi.switchTenant, {});
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify({ TenantId: newTenantId }) }, 'Tenants');        
    }  
    
    async auditUsers(tenantId){   
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: 'users' });
        return this.fetch(this.baseApiAddress + url + '?operation=audit', {}, 'Users');        
    }     
    
    async auditLogins(tenantId){   
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: 'users' });
        return this.fetch(this.baseApiAddress + url + '?operation=logins', {}, 'Users');        
    }   

    async updateUser(newUserObj){   
        const url = Routes.compose(Routes.clientApi.users, {});
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(newUserObj) });        
    } 

    async generateUserToken(tenantId, userId){   
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: 'users' });
        return this.fetch(this.baseApiAddress + url + '?operation=generateToken', { method: 'POST', body: JSON.stringify({ UserId: userId }) }, 'Users');        
    }    
    
    async uptimeReport(dateStr){   
        const url = Routes.compose(Routes.adminApi.uptimeReport, {});
        return this.fetch(this.baseApiAddress + url + '?since=' + dateStr, {}, 'Uptime');        
    } 
    
    async notify(messageDetails){   
        const url = Routes.compose(Routes.clientApi.notify, {});
        return this.fetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify(messageDetails) });        
    } 

    async loadViaProxy(urlToProxy) {
        const url = Routes.compose(Routes.clientApi.proxy, {}, { url: urlToProxy });
        return this.fetch(this.baseApiAddress + url, {});
    } 
    
    async loginFetch(url, options, itemName, isSilent){   
        let defaultOptions = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',    
            signal: this.abortController.signal,        
            headers: {
                'Content-Type':'application/json'          
            }
        }

        
        let newOptions = Object.assign(defaultOptions, options);
        
        return await fetch(url, newOptions)
            .then(responseText => {
                return responseText.json()
            })
            .then(responseObj => {
                if (responseObj.status === 200){
                    return responseObj.data
                }else{
                    throw responseObj;
                }
            })       
            .catch((ex) => {                
                console.log('API Error:', ex);         
                throw ex;
            })
            .finally(() => {
                // if (itemName && !isSilent)EM.dispatch(fetchingActions.endFetch("Authentication"));   
            });
    }

    async sendGridStats({campaignId, start_date}) {
        const url = Routes.compose(Routes.clientApi.sendGridStats, {campaignId, start_date});
        return this.fetch(this.baseApiAddress + url);   
    } 

    async fetch(url, options, itemName, isSilent){   
        if (itemName && !isSilent){
            EM.dispatch(fetchingActions.startFetch(itemName))
        };      
        let defaultOptions = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',    
            signal: this.abortController.signal,        
            headers: { 
                'Content-Type':'application/json'          
            }
        }

        let allowAnonymous = await this.Authenticator.allowAnonymous();        
        var token = '' ;
        if(allowAnonymous!==true)
        {
            token = await this.Authenticator.acquireToken(); 
            if (!token){
                throw { message: 'Token acquisition failed.', name: 'acquisitionFailure'};
            }
            defaultOptions.headers.Authorization =token;
        } 
            
        let newOptions = Object.assign(defaultOptions, options);
        return await fetch(url, newOptions)
            .then(responseText => {
                return responseText.json()
            })
            .then(responseObj => {                
                if (responseObj.status === 200){
                    return responseObj.data
                }else{
                    throw responseObj;
                }
            })       
            .catch((ex) => {    
                if (ex instanceof TypeError && ex.message === "Failed to fetch") {
                    throw 'Network Error: Please check your internet connection'
                } else {     
                    console.log('API Error:', ex);         
                    throw ex;
                }
            })
            .finally(() => {
                if (itemName && !isSilent)EM.dispatch(fetchingActions.endFetch(itemName));   
            });
    }

    async fetchFile(url, options, itemName, isSilent){   
        if (itemName && !isSilent)EM.dispatch(fetchingActions.startFetch(itemName));        

        var token = await this.Authenticator.acquireToken();
        if (!token){
            throw { message: 'Token acquisition failed.', name: 'acquisitionFailure'};
        }

        let defaultOptions = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',            
            headers: {
                'Authorization': token          
            }
        }
        let newOptions = Object.assign(defaultOptions, options);
        
        return await fetch(url, newOptions)
            .then(responseText => {
                return responseText.json()
            })
            .then(responseObj => {
                if (responseObj.status === 'error'){
                    throw responseObj;
                }else{
                    return responseObj;
                }
            })                   
            .catch((ex) => {
                console.log('API Error:', ex);
                throw ex;
            })
            .finally(() => {
                if (itemName && !isSilent)EM.dispatch(fetchingActions.endFetch(itemName));   
            });
    }    
    
    async fetchStaticJson(url, options, itemName){   
        if (itemName)EM.dispatch(fetchingActions.startFetch(itemName));        
        return await fetch(url, options)
            .then(responseText => {
                return responseText.json()
            })     
            .catch(function (ex, b) {
                console.log('Fetch Static Error:', ex);
                throw ex;
            })
            .finally(() => {
                if (itemName)EM.dispatch(fetchingActions.endFetch(itemName));   
            });
    }  
    
    async fetchStaticText(url, options){   
        return await fetch(url, options)
            .then(responseText => {
                return responseText.text()
            })      
            .catch(function (ex, b) {
                console.log('Fetch Static Error:', ex);
                throw ex;
            });
    }      
}